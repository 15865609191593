import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/Layout";
import { getBlockContent, getBlockImage } from "../utils/helpers";
import SpeakerCard from "../components/layout/SpeakerCard";

// Primitives
// import EmojiIcon from "../components/primitives/EmojiIcon";
import Grid from "../components/primitives/Grid";

const Speakers = ({ data }) => {
  const { persons = [] } = data.allSanityPersonsList.nodes[0] || [{}];
  const { pageTitle, shoutOut, metaInfo, title, slug, _rawContent } =
    data?.sanityPages || {};

  const metaStructure = {
    title: metaInfo?.header || title,
    someTitle: metaInfo?.someHeader || pageTitle,
    description:
      metaInfo?.description || getBlockContent(_rawContent) || shoutOut,
    fullSlug: slug?.current,
    image: metaInfo?.image || getBlockImage(_rawContent)?.image,
    imageAlt: metaInfo?.image
      ? metaInfo?.imageAlt
      : getBlockImage(_rawContent)?.alt,
  };

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  return (
    <Layout metaInfo={metaStructure}>
      <Grid>
        <div className="col-span-12">
          <h1 className="text-4xl uppercase motion-safe:animate-symbol-bounce-slow">
            {pageTitle}
          </h1>
        </div>

        <div className="col-span-12 lg:col-span-8 xxl:col-span-6">
          {shoutOut && <p className="font-bold">{shoutOut}</p>}
        </div>

        {/* Spacer */}
        <div className="col-span-12 lg:col-span-4 xxl:col-span-6" />

        {persons.map((personRef, i) => {
          const person = personRef.ref;

          let positionOnTop = getRandomInt(2) === 1;
          return (
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4"
              key={personRef + i}
            >
              <SpeakerCard
                key={`speaker_${i}`}
                title={person.name}
                url={"/speaker/" + person.slug.current}
                position={person.stilling}
                company={person.company}
                language={person.language}
                imageUrl={person.image?.asset?.url}
                positionImageOnTop={positionOnTop}
              />
            </div>
          );
        })}
      </Grid>
    </Layout>
  );
};

export default Speakers;

export const query = graphql`
  query {
    sanityPages(slug: { current: { eq: "speakers-2022" } }) {
      pageTitle
      shoutOut
      _rawContent(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
      metaInfo {
        description
        header
        someHeader
        imageAlt
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityPersonsList(filter: { conferenceYear: { eq: "2022" } }) {
      nodes {
        persons {
          ref {
            id
            name
            stilling
            company
            language
            slug {
              current
            }
            image {
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
`;
